<template> 
    <div class="container mt-10 mb-40">
      <div class="row">
        <div class="col-lg-3 col-6 mb-10" :key="index" v-for="(items, index) in dataTour.slice(0,4)">
          <div class="card shadow h-100 card-for-tour cursor-pointer" @click="changePage(items)">
            <div class="img-home-umroh tour-card"  >
                <template v-if="items.images == 0">
                  <img src="@/assets/images/background/tour-1.jpg" :alt="items.slug">
                </template>
                <template v-else >
                   <div  >
                    <img v-bind:src="chesen(items)" :alt="items.slug" />
                  </div>
                </template>  
              <span class="labelTour font-16 font-weight-6 text-white hide-mobile">{{ items.travel }}</span>
            </div>
            <div class="title pad-10">
              <span
                class="d-block w-100 font-weight-6 font-12"
              >{{ items.nama }}</span>
              <span class="d-block w-100 font-14 mt-2 text-warning font-weight-6 hide-mobile">Starting/ Person</span>
              <span class="d-block w-100 font-16 text-danger font-weight-7 float-right">IDR {{ items.harga | numeralFormat('0,0[.]00') }}</span>
            </div>
          </div>
        </div>
        
      </div>
      <template  >
         <div class="col-md-12 text-center justify-center mt-10" >
      <span @click.prevent="changeShow()" class=" d-block width-20 w-res-100 w-res-tab-small-80   margin-auto text-center border-radius-100 bg-warning cursor-pointer text-white  pad-10 font-18 font-weight-6">Lihat Semua 
          <i class="fa ml-2 fa-arrow-right"></i></span> 
        </div>
      </template> 
    </div> 
</template>
<script>
export default {
  data() {
    return {
      dataTour:[],
      dataImages:{},
      dataPagging:[],
      numberCount: 8,
      showCount:null,
      totalCount:null,
      parse:null
    };
  },
  mounted() {
     localStorage.removeItem("response-tour");
    //  this.parseMethod()
    // this.totalCount = this.dataparse.data.length
    //  console.log(this.dataTour.data)
 
    //  this.dataImages.push(element.images.file)
        // let ab = this.dataTour.data
        //  ab.forEach(element => {
        //    console.log(element.images[0])
        //  });
      //  var result = Object.keys(ab).map(function(key) {
      //     return [Number(key), ab[key]];
      //   });
        // console.log(result)
        //  return element[0]
   
 
    this.getData();
  },
  methods: {
      getData() {
      let param = new FormData();
      param.append("api_key", "2222");
      param.append("format", "");
      param.append("ws", "tour");
      param.append("type", "tour"); 
      param.append("keyword", ''); 
      param.append("call", "list_product"); 
      this.axios
        .post("https://apitiket.realtravel.co.id/ws", param, {
          headers: new Headers({
            accept: "application/json",
            "Content-Type": "multipart/form-data, text/plain"
          })
        })
        .then(response => {
          let res = response.data;
         this.dataparse = res
        //  console.log(res)
          // localStorage.setItem("response-tour", JSON.stringify(res));
        this.parseMethod()
          // this.destination = res.result
         
        })
        .catch(error => {
          error;
        });
    },
    parseMethod(){
        this.dataTour = this.dataparse.data.slice(0, this.numberCount)
    },
    // countMethod(){
    //   this.numberCount = this.numberCount + 4
    //     this.parseMethod()
       
    // },
    chesen(val){
      return val.images[0].file
      // return val[0]
    },
    changeShow(){
       this.$router.push("/list-all-tour");
    },
    changePage(NewVal){
      this.$router.push('/tour/'+NewVal.slug)
    }
  },
};
</script>