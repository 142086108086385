<template>
  <div v-if="cabangParam != null">
    
    <!-- <div v-if="cabangParam == 'jakarta'">
        <cabang-jakarta></cabang-jakarta>
    </div>
    <div v-if="cabangParam == 'makasar'">
        <cabang-makasar></cabang-makasar>
    </div>
    <div v-if="cabangParam == 'bandung'">
        <cabang-bandung></cabang-bandung>
    </div>
    <div v-if="cabangParam == 'maros'">
        <cabang-maros></cabang-maros>
    </div> -->
    <div>
    <div class="container-fluid header-sub " style="">
      <div class="container-standar h-100 "><div class="breadcumb-container"></div></div>
    </div>
    <div>
    <div class="container-standar text-center">
      <div class="dk-container card shadow">
        
        <div class="contact-image">
          <img src="@/assets/images/real-travel.png" alt="real-travel" />
        </div>
        <h3 class="text-primary text-center font-weight-6 font-18">
            <img class="width-15 margin-auto" src="@/assets/images/name-logo.png" alt="real-travel" />
        </h3>

        <h1 class="d-block font-30 text-primary mt-4 font-weight-7 ">PAKEM TOUR <span style="text-transform: uppercase;">{{dataCabang.name}}</span></h1>
        <span
                class="d-inline-block font-14 font-mobile-14 font-weight-7 text-primary width-100"
              >{{dataCabang.address}}
                  <br />{{dataCabang.location}}<br> No.telp {{dataCabang.phone}}</span>
        
        <div class="ff-doa mt-2 pad-20 pad-mobile-20">
          <div class="row">
            <div class="col-md-6 text-left">
              
              <p class="font-weight-5 mt-2 font-16 font-mobile-14 hide-mobile">
                <span
                class="d-inline-block text-grey font-14 font-mobile-12"
              >PT. Pandi Kencana Murni</span> Berdiri Sejak Tahun 1996 dan didirikan oleh Bapak H. Hamimzar Arif, S.Sos Selaku CEO. Pakem Tours Memiliki Izin Haji : <strong class="text-primary">PHU/PIHK 3154/VIII/2011 Nomor : 529 tahun 2017</strong> dan Izin Umrah, <strong class="text-primary">SK No: U-142 TAHUN 2020</strong> (Perpanjangan) Serta Memiliki Izin Kepariwisataan Dan Masuk Sebagai Anggota Asita, Anggota Himpunan Penyelenggara Umroh dan Haji ( Himpuh ). Pakem Tours Telah Bekerja sama dengan Seluruh Airline / Penerbangan.  Telah Berpengalaman Memberangkatkan Puluhan ribu Jamaah Haji, Umroh dan Tour. Sehingga Mendapatkan penghargaan dari Kementerian Pariwisata dan Kementerian Agama Sebagai “ Best Excellent Service” Travel Penyelenggara Haji dan Umroh.
              </p>
            </div>
            <div class="col-md-6 text-center">
            <div class="card">
              <iframe :src="dataCabang.koordinat_lokasi" width="100%" height="250" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
              </div>
            </div>
            
          </div>
          
        </div>
        
        <p class="mt-10 mb-30 text-center">
        <img class="margin-auto" src="@/assets/images/icon-salam.png" alt="real-travel" />
            <a href="https://wa.me/6287823237339" v-if="cabangParam == 'jakarta'" class="btn bg-warning advance-search text-white font-weight-6 pb-2 font-16"><span><i class="fa fa-whatsapp"></i> Konsultasi Sekarang </span></a>
            <a href="https://wa.me/628114441974" v-if="cabangParam == 'makassar'" class="btn bg-warning advance-search text-white font-weight-6 pb-2 font-16"><span><i class="fa fa-whatsapp"></i> Konsultasi Sekarang </span></a>
            <a href="https://wa.me/6282172549765" v-if="cabangParam == 'pekanbaru'" class="btn bg-warning advance-search text-white font-weight-6 pb-2 font-16"><span><i class="fa fa-whatsapp"></i> Konsultasi Sekarang </span></a>
            <a href="https://wa.me/6281807333444" v-if="cabangParam == 'banten'" class="btn bg-warning advance-search text-white font-weight-6 pb-2 font-16"><span><i class="fa fa-whatsapp"></i> Konsultasi Sekarang </span></a>
            <a href="https://wa.me/6282323288211" v-if="cabangParam == 'jepara'" class="btn bg-warning advance-search text-white font-weight-6 pb-2 font-16"><span><i class="fa fa-whatsapp"></i> Konsultasi Sekarang </span></a>
            <a href="https://wa.me/6289513942771" v-if="cabangParam == 'bandung'" class="btn bg-warning advance-search text-white font-weight-6 pb-2 font-16"><span><i class="fa fa-whatsapp"></i> Konsultasi Sekarang </span></a>
            <a href="https://wa.me/6282177821200" v-if="cabangParam == 'lampung'" class="btn bg-warning advance-search text-white font-weight-6 pb-2 font-16"><span><i class="fa fa-whatsapp"></i> Konsultasi Sekarang </span></a>
            <a href="https://wa.me/6285268749242" v-if="cabangParam == 'palembang'" class="btn bg-warning advance-search text-white font-weight-6 pb-2 font-16"><span><i class="fa fa-whatsapp"></i> Konsultasi Sekarang </span></a>
            <a href="https://wa.me/628111480503" v-if="cabangParam == 'maros'" class="btn bg-warning advance-search text-white font-weight-6 pb-2 font-16"><span><i class="fa fa-whatsapp"></i> Konsultasi Sekarang </span></a>
            <a href="https://wa.me/628114100490" v-if="cabangParam == 'polewali'" class="btn bg-warning advance-search text-white font-weight-6 pb-2 font-16"><span><i class="fa fa-whatsapp"></i> Konsultasi Sekarang </span></a>
        </p>
      </div>
      
    </div>

    <div class="container-fluid mb-10 mb-res-10 bg-customise-content pt-10 pb-1">
      <section class="mb-50 pt-15">
        <div class="container">
          <div class="row">
            <div class="col col-md-12 text-center mb-20 tiitle-ex">
              <span class="d-block text-white font-28 font-weight-7 roboto">PAKET UMROH</span>
              <span class="d-block text-white font-20" v-if="rest_no != 404">Paket Umroh Terbaik Untuk Anda</span>
              <span span class="d-block text-white font-20" v-if="rest_no == 404">Data untuk {{dataCabang.name}} tidak ditemukan.</span>
            </div>
            <div class="row" v-if="rest_no != 404">
              <div class="row margin-auto">
                <div class="col-lg-3 col-6 mb-30" v-for="(items, i) in dataTour" :key="i">
                    <div class="card shadow h-100 bg-white width-100 pad-10 d-inline-table pos-relative cursor-pointer" @click="changePage(items)">
                        <div class="container-maskapai hide-mobile">
                            <img v-bind:src="items.airline[0].logo" alt/>
                        </div>
                        <div class="img-home-umroh">
                            <template v-if="items.images == 0">
                            <img src="@/assets/images/background/tour-1.jpg" alt="">
                            </template>
                            <template v-else >
                                <div>
                                <img v-bind:src="chesen(items)" style="width:100%" alt />
                            </div>
                            </template>
                        </div>
                        <div class="content mt-2">
                            <span class="d-inline-block font-12 text-primary font-weight-7">{{items.nama}}</span>
                            <p class="umroh_hotel">
                            <span class="d-inline-block width-100 float-right text-left font-weight-7 text-warning font-12 price-umroh">
                                {{getDate(items.date_depart)}} <b class="hide-mobile">- {{items.day}} Hari</b>
                            </span>
                            </p>
                            <p class="umroh_hotel hide-mobile" v-for="(hotel, j) in items.hotel" :key="j">
                            {{hotel.name}}
                            <span>
                                <i v-for="n in parseInt(hotel.star)" :key="n" class="fa fa-star"></i>
                            </span>
                            </p>
                        </div>                          
                          <span class="d-inline-block width-50 float-left text-left mt-6 font-weight-7 text-warning font-16 price-umroh"
                          v-bind:class="{'text-decoration': items.diskon != 0}">
                            IDR {{ items.amount | numeralFormat('0,0[.]00') }}</span>
                          <span class="d-inline-block width-50 float-right text-right mt-6 font-weight-7 text-warning font-14 price-umroh" v-if="items.diskon != 0">
                            IDR {{ items.amount - items.diskon | numeralFormat('0,0[.]00') }}</span>
                      </div>
                    </div>
                    <div class="col-md-12 text-center mt-10">
                        <router-link to="/paket-umroh">
                        <span class="bg-white pt-2 pb-2 btn-responsive-home opensans font-weight-6 text-primary margin-auto w-50 text-center d-block cursor-pointer font-20 border-radius-5">
                        Lihat Semua
                        <i class="fa ml-2 fa-arrow-right"></i>
                        </span>
                        </router-link>
                    </div>
                </div>
            </div>
            <!-- <div class="row" v-else>
                <div class="col-lg-12">
                    <span span class="d-block text-white font-20">Data untuk {{cabangParam}} tidak ditemukan.</span>
                </div>
            </div> -->
          </div>
        </div>
      </section>
    </div>

    <div class="container-fluid mb-20">
       <div class="col-md-12 mt-10 mb-10 text-center">
        <span class="d-block text-primary font-28 font-weight-7 roboto">PAKET TOUR</span>
        <span class="d-block text-grey font-20">Pilihan Destinasi Wisata Terbaik</span>
      </div>
      <div class="row">
      <beritaList></beritaList>
      </div>
                
    </div>

    </div>

    </div>

  </div>
</template>

<script>
// import PackageUmroh from "@/views/components/package-umroh.vue";
import beritaList from "@/views/components/berita-list";
import * as moment from 'moment' 
export default {
  components: {
    // PackageUmroh,
    beritaList
  },
  data() {
    return {
        cabangParam:'',
        dataCabang:[],
        dataTour:[],
        posts: [],
        numberCount: 4,
        totalCount:null,
        parse:null,
        rest_no:null,
        err: []
    };
  },
  mounted() {
    this.$store.commit("SET_BG", true);
    this.cabangParam = this.$route.params.cabang;
    this.getDataCabang();
    this.getDataUmroh();
  },
  methods:{
    getDataCabang(){
      let param = new FormData();
      param.append("api_key", "2222");
      param.append("format", "");
      param.append("ws", "umroh"); 
      param.append("call", "list_cabang");
      if (this.cabangParam == 'bandung') {
        param.append("id_cabang", "dDVONGN0OFhhUGtDaDN4NXc2dENXdz09");          
      }else if (this.cabangParam == 'banten') {
        param.append("id_cabang", "azY4WlBPRXBOS1ZPR2xKZEY0TlhZdz09");          
      }else if (this.cabangParam == 'jakarta') {
        param.append("id_cabang", "dExHbEJVYzdwZWwxYys4ZVJGck92UT09");          
      }else if (this.cabangParam == 'jepara') {
        param.append("id_cabang", "YkhxM2lCb2pmbndWYldhbEc1ZnV4QT09");          
      }else if (this.cabangParam == 'lampung') {
        param.append("id_cabang", "Nm1VTWN4cWtGT3B1WFdnZWRSRTlldz09");          
      }else if (this.cabangParam == 'makassar') {
        param.append("id_cabang", "R1V6YkVBc1RMTWxEZVhXQzlCT3ZVUT09");          
      }else if (this.cabangParam == 'maros') {
        param.append("id_cabang", "QnN2dnNwSDQyeFJndFhpZXFtakxOZz09");          
      }else if (this.cabangParam == 'palembang') {
        param.append("id_cabang", "b3ZkQncrVnFpNkFvUHM2Y24wNDZTQT09");          
      }else if (this.cabangParam == 'pekanbaru') {
        param.append("id_cabang", "RzBtSEhjRHAzSUFScEtNWUowejhCZz09");          
      }else if (this.cabangParam == 'polewali') {
        param.append("id_cabang", "cXFyYnhTOHcwWW95V2swYkN5Z0twZz09");          
      }else{
        this.cabangParam = null;
      }
      this.axios
        .post("https://apitiket.realtravel.co.id/ws", param, {
            headers: new Headers({
              accept: "application/json",
              "Content-Type": "multipart/form-data, text/plain"
            })
          })
        .then(response => {
          let res = response.data
          if(res.rest_no == 0){ 
            this.dataCabang = res.data[0]
            console.log(this.dataCabang)
          }
      });
    },
    getDataUmroh() {
        let param = new FormData();
        param.append("api_key", "2222");
        param.append("format", "");
        param.append("ws", "umroh");
        param.append("keyword", ''); 
        param.append("call", "list_umroh"); 
        param.append("cabang", this.cabangParam); 
        this.axios
        .post("https://apitiket.realtravel.co.id/ws", param, {
            headers: new Headers({
              accept: "application/json",
              "Content-Type": "multipart/form-data, text/plain"
            })
        })
        .then(response => {
            let res = response.data;
            this.dataparse = res
            if (res.rest_no == 0) {
                this.parseMethod()
                this.totalCount = this.dataparse.data.length     
                this.rest_no = this.dataparse.rest_no            
            } else {
                this.rest_no = this.dataparse.rest_no      
            }
        })
        .catch(error => {
            error;
        });
    },
    parseMethod(){
        this.dataTour = this.dataparse.data.slice(0, this.numberCount)
    },
    countMethod(){
        this.numberCount = this.numberCount + 4
        this.parseMethod()    
    },
    chesen(val){
        return val.image
    },
    getDate(val){
        moment.locale('ID'); 
        return moment(val).format('LL')
    },
    changePage(NewVal){
      localStorage.setItem("response-date-depart", JSON.stringify(NewVal.date_depart));
      this.$router.push('/paket-umroh/detail/'+NewVal.slug+'/'+NewVal.date_depart)
    }
  }
};
</script>